import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {
  "title": "Create a good serverless developer experience",
  "description": "How to setup your project for a pleasant developer experience without servers",
  "image": "./img/good-dx.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "create-a-good-serverless-developer-experience"
    }}>{`Create a good serverless developer experience`}</h1>
    <p><img parentName="p" {...{
        "src": "/ab0f5ebf994436a545879739fc190a73/good-dx.svg",
        "alt": null
      }}></img></p>
    <p>{`What makes a good developer experience?`}</p>
    <p>{`I asked twitter and it was all over the place. A theme emerged:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Good developer experience is when tools make your job easier, get out of the way, and let you focus on `}<em parentName="p">{`your`}</em>{` code`}</p>
    </blockquote>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller speaking at Reactathon avatar"
          }}></img><b parentName="a">{`Swizec Teller speaking at Reactathon`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`What makes a good developer experience?`}</blockquote>{`
        
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1195742841877610496"
          }}>{`4:38:03 PM – 11/16/2019`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1195742841877610496",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`16`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1195742841877610496",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`27`}</a></div>{`
    `}</div></div>
    <p>{`How do you setup a serverless project for good DX?`}</p>
    <p>{`It comes down to 3 features:`}</p>
    <ol>
      <li parentName="ol">{`Infrastructure-as-code`}</li>
      <li parentName="ol">{`Fast deploys`}</li>
      <li parentName="ol">{`Tooling for common tasks`}</li>
    </ol>
    <h2 {...{
      "id": "infrastructure-as-code"
    }}>{`Infrastructure-as-code`}</h2>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/GWmArmdNA2"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1488352068377399296/cqkaijsH_normal.jpg",
            "loading": "lazy",
            "alt": "metahearse avatar"
          }}></img><b parentName="a">{`metahearse`}</b>{`@muditameta`}</a>{`
        `}<blockquote parentName="div"><a parentName="blockquote" {...{
            "href": "https://twitter.com/Swizec"
          }}>{`@Swizec`}</a>{` A setup that lets the developer work at their pace on building something without ever having to worry about tooling, CI, and other accidental complexity.`}<br parentName="blockquote"></br>{`In other words, a setup optimized for iteration and low accidental complexity introduced noise.`}</blockquote>{`
        
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/muditameta/status/1195783892512444417"
          }}>{`7:21:11 PM – 11/16/2019`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1195783892512444417",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`0`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/muditameta/status/1195783892512444417",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`0`}</a></div>{`
    `}</div></div>
    <p>{`As mentioned in the `}<a parentName="p" {...{
        "href": "https://serverlesshandbook.dev/getting-started#setup-for-serverless-work"
      }}>{`Getting Started`}</a>{` chapter, I like to use the open source `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Serverless_Framework"
      }}>{`Serverless Framework`}</a>{` with AWS. When using Netlify and Vercel you don't need Serverless because config-as-code is baked into their philosophies.`}</p>
    <p>{`You write a configuration file, add it to version control, and that's your infrastructure. `}<em parentName="p">{`Nothing`}</em>{` happens outside that configuration file.`}</p>
    <p>{`This means that:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Your deploys are repeatable`}</strong>{`. Run deploy, get the same result every time. The same functions, the same queues, the same caching servers, everything.`}</li>
      <li parentName="ol"><strong parentName="li">{`Same infrastructure in test as in prod`}</strong>{` Subtle differences between test environments and production are a waste of time. Big part of why Docker got popular.`}</li>
      <li parentName="ol"><strong parentName="li">{`Share infrastructure between the team`}</strong>{`. Ever had to ask a team member what environment variable they used for a thing? I have. After 2 hours of digging into the problem and realizing it's a configuration issue. 🤦‍♂️`}</li>
      <li parentName="ol"><strong parentName="li">{`Infrastructure that always fits your feature branch`}</strong>{` A common problem are new features with different infrastructure. Like adding a new queue or cloud function. Instead of setting it up every time you test, infra-as-code can do it for you.`}</li>
      <li parentName="ol"><strong parentName="li">{`Spend time in the tools you like, not confusing web UI`}</strong>{` We're engineers and we like building things. Not clicking around a web UI doing repetitive tasks that take 20 minutes.`}</li>
    </ol>
    <h2 {...{
      "id": "fast-deploys"
    }}>{`Fast deploys`}</h2>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": ""
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1051492344695001090/P1VRAeos_normal.jpg",
            "loading": "lazy",
            "alt": "Mikey avatar"
          }}></img><b parentName="a">{`Mikey`}</b>{`@CodingDive`}</a>{`
        `}<blockquote parentName="div"><a parentName="blockquote" {...{
            "href": "https://twitter.com/Swizec"
          }}>{`@Swizec`}</a>{` An intuitive api and very short feedback cycles.`}</blockquote>{`
        
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/CodingDive/status/1195776781921464321"
          }}>{`6:52:55 PM – 11/16/2019`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1195776781921464321",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`1`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/CodingDive/status/1195776781921464321",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`0`}</a></div>{`
    `}</div></div>
    <p>{`The shorter your feedback cycle, the faster you can work.`}</p>
    <p>{`On the frontend we have local dev servers and hot reloading. You see the result almost as fast as you write the code.`}</p>
    <p>{`On the backend things are trickier.`}</p>
    <p>{`You make a change ... now what? If you have unit tests, they show you part of the picture. The specific scenarios you thought to test, the methods you're exercising, the particular inputs.`}</p>
    <p>{`All great.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/WO74HAtUC9I40/giphy.gif",
        "alt": null
      }}></img></p>
    <p>{`But unit tests can't tell you your `}<em parentName="p">{`system`}</em>{` works. That's where bugs come from – systems complexity.`}</p>
    <p>{`You can simulate the environment and run your tests. That works to an extent, but it's never perfect.`}</p>
    <p>{`Your best bet is to make deploying to a staging, QA, or production environment fast enough to use for development. With serverless, that becomes possible.`}</p>
    <p>{`You could even set it up so that pushing to GitHub deploys every branch. Netlify and Vercel call it pull request previews.`}</p>
    <h3 {...{
      "id": "how-fast-deploys-work"
    }}>{`How fast deploys work`}</h3>
    <p>{`Here's how the flow works:`}</p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">{`Hit deploy`}</li>
      <li parentName="ol"><strong parentName="li">{`Compile your code locally`}</strong>{` on your fast developer machine. Since your code is small, it compiles in seconds.`}</li>
      <li parentName="ol"><strong parentName="li">{`Compile your infrastructure`}</strong>{` the serverless framework compiles your infrastructure into a config file for the target platform. With AWS that's `}<a parentName="li" {...{
          "href": "https://aws.amazon.com/serverless/sam/"
        }}>{`SAM`}</a>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Upload your bundle`}</strong>{` this is the slowest part.`}</li>
      <li parentName="ol"><strong parentName="li">{`Infrastructure sets itself up`}</strong>{` using your config the platform sets itself up. Servers appear, queues go up, etc. Takes a few seconds`}</li>
      <li parentName="ol"><strong parentName="li">{`You're ready to go`}</strong></li>
    </ol>
    <div id="lock" />
    <p>{`Slowest deploys I've seen on production-sized backends are in the 2 minute range. That's for a system with hundreds of lines of configuration.`}</p>
    <p>{`On my side projects it's 30 seconds.`}</p>
    <p>{`That's fantastic compared to a Heroku or Docker deploy that takes 20 minutes.`}</p>
    <h2 {...{
      "id": "tooling-for-common-tasks"
    }}>{`Tooling for common tasks`}</h2>
    <p>{`I like to use my project's `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` as a collection of scripts for common tasks. `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` or `}<inlineCode parentName="p">{`npm run`}</inlineCode>{` make them easy to run.`}</p>
    <p>{`The most common is `}<inlineCode parentName="p">{`yarn deploy`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`# package.json

"scripts": {
    "build": "tsc build",
    "deploy": "npm run build && sls deploy"
}
`}</code></pre>
    <p>{`With those 2 lines you can deploy from any branch without worry that you'll forget to build your project first. The `}<inlineCode parentName="p">{`build`}</inlineCode>{` script runs a typescript build and `}<inlineCode parentName="p">{`sls deploy`}</inlineCode>{` runs a serverless deploy.`}</p>
    <p>{`This part gets trickier when you use multiple environments. We'll talk about that in `}<a parentName="p" {...{
        "href": "/dev-qa-prod"
      }}>{`the chapter on prod, QA, and staging environments`}</a>{`.`}</p>
    <p>{`Other helpful tools I've set up for bigger projects include:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`yarn psql`}</inlineCode>{` to connect to my remote database`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`reset-env`}</inlineCode>{` to reset a remote database for testing`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`test-X`}</inlineCode>{` to run different tests against the server environment`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`add-engineer`}</inlineCode>{` to add a new engineer-specific environment so everyone can test on their own`}</li>
    </ul>
    <p>{`Any time you find yourself running the same sequence of commands, you should consider adding them as a script to `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`. Give others that same super power :)`}</p>
    <h1 {...{
      "id": "how-this-works-in-practice"
    }}>{`How this works in practice`}</h1>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "undefined",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`In the next few minutes you're going to build your first serverless backend. A service that says Hello 👋`}</p>
    <p>{`We're using open source technologies and deploying on AWS Lambda. You can learn about other providers in the `}<a parentName="p" {...{
        "href": "/serverless-flavors"
      }}>{`Serverless Flavors`}</a>{` chapter.`}</p>
    <p>{`You'll need a computer configured for JavaScript development: Have nodejs installed, a code editor, and a terminal.`}</p>
    <h3 {...{
      "id": "setup-for-serverless-work"
    }}>{`Setup for serverless work`}</h3>
    <p>{`When working with serverless I like to use the open source `}<a parentName="p" {...{
        "href": "https://github.com/serverless/serverless"
      }}>{`Serverless`}</a>{` framework. We'll talk more about why in the `}<a parentName="p" {...{
        "href": "/serverless-dx"
      }}>{`Good serverless dev experience`}</a>{` chapter.`}</p>
    <p>{`With the serverless framework we're going to configure servers using YAML files. You write config, framework figures out the rest.`}</p>
    <p>{`Install it globally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install -g serverless
`}</code></pre>
    <p>{`You'll need AWS credentials too.`}</p>
    <p>{`I recommend following `}<a parentName="p" {...{
        "href": "https://serverless.com/framework/docs/providers/aws/guide/credentials/"
      }}>{`Serverless's guide on AWS setup`}</a>{`. It walks you through the necessary steps on your Amazon account and a couple terminal commands to run.`}</p>
    <h3 {...{
      "id": "create-a-tiny-project"
    }}>{`Create a tiny project`}</h3>
    <p>{`There are no special initializers for serverless projects. You start with a directory and add a configuration file.`}</p>
    <p><img parentName="p" {...{
        "src": "/b7b9ac343e3dcec6c3e825fb940b2da6/start-serverless.gif",
        "alt": null
      }}></img></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`mkdir hello-world
cd hello-world
touch serverless.yml
touch handler.js
`}</code></pre>
    <p>{`You now have a project with 2 files:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`serverless.yml`}</inlineCode>{` for configuration`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`handler.js`}</inlineCode>{` for server code`}</li>
    </ul>
    <p>{`In future chapters you'll write backends using TypeScript. But one thing at a time :)`}</p>
    <h3 {...{
      "id": "configure-your-first-server"
    }}>{`Configure your first server`}</h3>
    <p>{`Configuration for your server goes in `}<inlineCode parentName="p">{`serverless.yml`}</inlineCode>{`. We're telling the Serverless framework that we want to use AWS, run nodejs, and that this is a dev project.`}</p>
    <p>{`Then we'll tell it where to find the code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

service: hello-world

provider:
  name: aws
  runtime: nodejs12.x
  stage: dev
`}</code></pre>
    <p>{`Our service is called `}<inlineCode parentName="p">{`hello-world`}</inlineCode>{` and there's a couple details about our provider. The `}<inlineCode parentName="p">{`stage`}</inlineCode>{` tells the difference between development, QA, and production deployments. More on that in the `}<a parentName="p" {...{
        "href": "/dev-qa-prod"
      }}>{`Dev, QA, and prod`}</a>{` chapter.`}</p>
    <h4 {...{
      "id": "lets-tell-our-server-how-to-run-code"
    }}>{`Let's tell our server how to run code.`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

service: hello-world

provider:
    name: aws
    runtime: nodejs12.x
    stage: dev

functions:
    hello:
        handler: ./handler.hello
        events:
            - http:
                  path: hello
                  method: GET
                  cors: true
`}</code></pre>
    <p>{`We started a `}<inlineCode parentName="p">{`functions`}</inlineCode>{` section.`}</p>
    <p>{`Each entry becomes its own tiny server – a serverless lambda. Together, they're the `}<inlineCode parentName="p">{`hello-world`}</inlineCode>{` service.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`hello`}</inlineCode>{` lambda calls an exported `}<inlineCode parentName="p">{`hello`}</inlineCode>{` function inside our `}<inlineCode parentName="p">{`handler.js`}</inlineCode>{` file when a GET request hits `}<inlineCode parentName="p">{`/hello`}</inlineCode>{`.`}</p>
    <p>{`All that from these few lines of code 👌`}</p>
    <p><em parentName="p">{`PS: enabling `}<a parentName="em" {...{
          "href": "https://en.wikipedia.org/wiki/Cross-origin_resource_sharing"
        }}>{`CORS`}</a>{` lets you call this function from other websites. Like your frontend app.`}</em></p>
    <h3 {...{
      "id": "write-your-first-backend-function"
    }}>{`Write your first backend function`}</h3>
    <p>{`Backend functions in a serverless environment look like the JavaScript functions you're used to. Grab arguments, return a response.`}</p>
    <p>{`Add a hello function to `}<inlineCode parentName="p">{`handler.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// handler.js

exports.hello = async (event) => {
  return {
    statusCode: 200,
    body: "Hello 👋",
  }
}
`}</code></pre>
    <p>{`It's an async function that accepts a trigger event and returns a response. A success status with a `}<inlineCode parentName="p">{`Hello 👋`}</inlineCode>{` body.`}</p>
    <p>{`That's it. You wrote backend code. 🤘`}</p>
    <h3 {...{
      "id": "deploy-your-first-serverless-backend"
    }}>{`Deploy your first serverless backend`}</h3>
    <p>{`To deploy, we run `}<inlineCode parentName="p">{`serverless deploy`}</inlineCode>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/bb63111bacbce5430ad52082f4712b4d/deploy-serverless.gif",
        "alt": null
      }}></img></p>
    <p>{`And your server is up.`}</p>
    <p>{`You get a URL for your lambda and some debugging output. My URL is `}<inlineCode parentName="p">{`https://z7pc0lqnw9.execute-api.us-east-1.amazonaws.com/dev/hello`}</inlineCode>{`, if you open it in your browser, it says `}<inlineCode parentName="p">{`Hello 👋`}</inlineCode></p>
    <TestCloudFunction serviceName="serverless-hello-world" urlPlaceholder="https://z7pc0lqnw9.execute-api.us-east-1.amazonaws.com/dev/hello" defaults mdxType="TestCloudFunction" />
    <p>{`I'll keep it up because it's free unless somebody clicks. And when they do, current AWS pricing gives me 1,000,000 clicks per month for free 😛`}</p>
    <h3 {...{
      "id": "what-you-got"
    }}>{`What you got`}</h3>
    <p>{`The Serverless framework talked to AWS and configured many things.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0fc470d202fe5ccd3b033d20108ef164/20a65/hello-world-lambda.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.15695067264574%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'220\\'%20viewBox=\\'0%200%20400%20220\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M18%2081c0%203%200%203%207%203h6c0%202%203%201%204-1h2c0%202%206%201%207-1s1-2-5-2h-8c-2-1-2-1-2%201h-1l-3-1h-3l-2-1-2%202m123%2050v5h11v-11h-11v6m15-2c0%202%200%202%201%201l1-1c0%202%203%202%204%201h1l4%201%203-1%202-1%201%201c0%202%209%201%2010-1h1c0%201%201%202%203%202s2%200%202-3c0-2%200-2-2-1h-21c0-1-1-1-1%201l-1%202v-2c0-2%200-2-1-1h-6c-1-1-1%200-1%202M15%20168v6h11v-12H15v6m205%200v6h11v-12h-11v6m44-3l1%203c8%201%2010%200%2011-1l1-1%202%202a271%20271%200%200111-1v-1l1%201h2l1%201c2%200%201-2-1-4h-2l-4%201-4%201h-1c1-2%200-2-1-2h-6c0%202-10%202-10%200-1-1-1%200-1%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0fc470d202fe5ccd3b033d20108ef164/ca0a1/hello-world-lambda.webp 223w", "/static/0fc470d202fe5ccd3b033d20108ef164/75680/hello-world-lambda.webp 445w", "/static/0fc470d202fe5ccd3b033d20108ef164/8d1ba/hello-world-lambda.webp 890w", "/static/0fc470d202fe5ccd3b033d20108ef164/3838e/hello-world-lambda.webp 1335w", "/static/0fc470d202fe5ccd3b033d20108ef164/e11e5/hello-world-lambda.webp 1780w", "/static/0fc470d202fe5ccd3b033d20108ef164/d1550/hello-world-lambda.webp 2490w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0fc470d202fe5ccd3b033d20108ef164/e92b6/hello-world-lambda.png 223w", "/static/0fc470d202fe5ccd3b033d20108ef164/e66bf/hello-world-lambda.png 445w", "/static/0fc470d202fe5ccd3b033d20108ef164/4ef49/hello-world-lambda.png 890w", "/static/0fc470d202fe5ccd3b033d20108ef164/4e814/hello-world-lambda.png 1335w", "/static/0fc470d202fe5ccd3b033d20108ef164/701e9/hello-world-lambda.png 1780w", "/static/0fc470d202fe5ccd3b033d20108ef164/20a65/hello-world-lambda.png 2490w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0fc470d202fe5ccd3b033d20108ef164/4ef49/hello-world-lambda.png",
              "alt": "hello world lambda",
              "title": "hello world lambda",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`API Gateway`}</strong>{` to proxy requests from the internet to your function`}</li>
      <li parentName="ul"><strong parentName="li">{`Lambda`}</strong>{` to run your code. This is a tiny container that wakes up when called.`}</li>
      <li parentName="ul"><strong parentName="li">{`CloudWatch logs`}</strong>{` to collect logs from your code. Helps with debugging.`}</li>
    </ul>
    <p>{`All those are configured for you. No UI to click through, no config to forget about next time, nothing your friends have to set up to deploy the same code.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/192b951ed50a3fe07fba64c02a996596/d74fe/hello-world.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.385650224215244%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'74\\'%20viewBox=\\'0%200%20400%2074\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%2012v12h401V0H0v12M163%202L96%203v18h304V1h-85L163%202m-23%207l-2%201h-4c-2-3-5-1-5%203l1%204c1-3%205-4%205-3l3%201v-2c-2%200-3-2-1-2l3%202c1%202%204%203%204%201h4l2%201%201%201c0%202%204-1%204-3s0-2%201%200l1%203%201-3c1-2%201-2%201%200s2%203%202%201h1c2%201%202%201%203-1%200-2%202-2%202%200%200%201%200%202%202%202s3-5%201-7c-2-1-6%200-6%202h-1c-2-1-2-1-2%201h-1l-4-2c-3%200-3%200-3%203l-1%204v-4c0-3-3-5-4-2-1%201-1%200-1-1%200-2%200-2-1-1h-2c-1-1-3-1-4%201m83%200c0%202%200%202-1%201h-16c-1-2-3%200-3%203%201%202%204%203%204%201s4-2%206%200c1%202%208%202%209%200h3l2%201-1-2-2-3c0-3-1-4-1-1m121%201c0%203%200%203-1%201s-8-3-8-1h-2c-2-2-5%201-3%203s4%202%204%201h1c1%201%201%201%202-1s1-2%201%200l1%202%201-2c1-2%201-2%201%200%200%203%203%203%204%200%200-3%200-3%201-1%200%202%201%203%203%203s2%200%202-4c0-3%200-4-1-3-1%202-4%202-4%200-1-1-1%200-2%202m-72%200l-1%203c1%203%207%203%207%200%201-2%201-2%201%200l1%202%201-2c1-2%201-2%201%200%200%201%201%202%206%202s6%200%205-1v-3c1-2%201-2-2-2-2%200-2%200-1%201v2l-1-1c-1-2-12-3-13-1h-4\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/192b951ed50a3fe07fba64c02a996596/ca0a1/hello-world.webp 223w", "/static/192b951ed50a3fe07fba64c02a996596/75680/hello-world.webp 445w", "/static/192b951ed50a3fe07fba64c02a996596/8d1ba/hello-world.webp 890w", "/static/192b951ed50a3fe07fba64c02a996596/4b3db/hello-world.webp 1164w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/192b951ed50a3fe07fba64c02a996596/e92b6/hello-world.png 223w", "/static/192b951ed50a3fe07fba64c02a996596/e66bf/hello-world.png 445w", "/static/192b951ed50a3fe07fba64c02a996596/4ef49/hello-world.png 890w", "/static/192b951ed50a3fe07fba64c02a996596/d74fe/hello-world.png 1164w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/192b951ed50a3fe07fba64c02a996596/4ef49/hello-world.png",
              "alt": "hello world",
              "title": "hello world",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Exciting!`}</p>
    <p>{`Next chapter, we talk about designing your serverless architecture.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      